﻿import { TranslateService } from '@ngx-translate/core';

export interface RequestContactMethod {
    contactmethodid: number;
    contactmethodname: string;
    contactmethoddisplayname: string;
}
// tslint:disable-next-line:class-name
export class ise_CustomerSettings {
    public CustomerName: string;
    public CustomerIdentifier: string;
    public ADFieldNameToValidateDisplayName: string;
    public EnablePasswordComplexity: boolean;
    public PasswordMinLength: number;
    public LogoBase64: string;
    public ADFSImageBase64: string;
    public EnableADFieldValidation: boolean;
    public ContactMethod: string;
    public IContactMethod: number;
    public ColorCode: string;
    public CustomerPageText: string;
    public ContactMethods: RequestContactMethod[];
    public CustomLink1: string;
    public CustomLink1Text: string;
    public CustomLink2: string;
    public CustomLink2Text: string;
    public SuccessMessage = 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit dem neuen Passwort anmelden.';

    public loadfromdata(data: string, translateService: TranslateService) {
        // tslint:disable-next-line:no-string-literal
        this.CustomerName = data['CustomerName'];
        // tslint:disable-next-line:no-string-literal
        this.CustomerIdentifier = data['CustomerIdentifier'];
        // tslint:disable-next-line:no-string-literal
        this.ADFieldNameToValidateDisplayName = data['ADFieldNameToValidateDisplayName'];
        // tslint:disable-next-line:no-string-literal
        this.EnablePasswordComplexity = data['EnablePasswordComplexity'];
        // tslint:disable-next-line:no-string-literal
        this.PasswordMinLength = data['PasswordMinLength'];
        // tslint:disable-next-line:no-string-literal
        this.LogoBase64 = data['LogoBase64'];
        // tslint:disable-next-line:no-string-literal
        this.ADFSImageBase64 = data['ADFSImageBase64'];
        // tslint:disable-next-line:no-string-literal
        this.EnableADFieldValidation = data['EnableADFieldValidation'];
        // tslint:disable-next-line:no-string-literal
        this.ContactMethod = data['ContactMethod'];
        if (this.ContactMethod) {
            this.ContactMethods = [];
            const tempstrArr = this.ContactMethod.split(';');
            if (tempstrArr) {
                for (let i = 0; i < tempstrArr.length; i++) {
                    const tempObj = <RequestContactMethod>{};
                    const tempStr = tempstrArr[i];
                    if (tempStr) {
                        tempObj.contactmethodname = tempStr;
                        if (tempStr === 'mail') {
                            tempObj.contactmethodid = 0;
                            tempObj.contactmethoddisplayname = translateService.instant('ContactMethodEmail');
                        } else if (tempStr === 'alternativeemail') {
                            tempObj.contactmethodid = 1;
                            tempObj.contactmethoddisplayname = translateService.instant('ContactMethodAlternativeEmail');
                        } else if (tempStr === 'mobile') {
                            tempObj.contactmethodid = 2;
                            tempObj.contactmethoddisplayname = translateService.instant('ContactMethodSMS');
                        }
                        this.ContactMethods.push(tempObj);
                    }
                }
            }
        }
        // tslint:disable-next-line:no-string-literal
        this.IContactMethod = data['IContactMethod'];
        // tslint:disable-next-line:no-string-literal
        this.ColorCode = data['ColorCode'];
        // tslint:disable-next-line:no-string-literal
        this.CustomerPageText = data['CustomerPageText'];
        // tslint:disable-next-line:no-string-literal
        this.CustomLink1 = data['CustomLink1'];
        // tslint:disable-next-line:no-string-literal
        this.CustomLink1Text = data['CustomLink1Text'];
        // tslint:disable-next-line:no-string-literal
        this.CustomLink2 = data['CustomLink2'];
        // tslint:disable-next-line:no-string-literal
        this.CustomLink2Text = data['CustomLink2Text'];
        try {
            // tslint:disable-next-line:no-string-literal
            const successMessage = data['SuccessMessage'];
            if (successMessage !== '') {
                this.SuccessMessage = successMessage;
            } else {
                this.SuccessMessage = 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit dem neuen Passwort anmelden.';
            }
        } catch {
            this.SuccessMessage = 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit dem neuen Passwort anmelden.';
        }
    }
}
