﻿import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ise_Request } from './request';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class PasswordResetService {
   // private static wsUrl = 'http://localhost:52040/service.ashx?';  // URL to web API
   // private static wsUrl = 'http://passwordresetter.isedev.ch/webservice/service.ashx?'; // URL to web API
   // private static wsUrl = 'https://password.sihlnet.ch/webservice/service.ashx?'; // URL to web API
   private static wsUrl = 'https://password.sihlnet.ch/api/v2/service.ashx?'; // URL to web API

  private callback: any;

  public static isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  constructor(private http: Http, private translate: TranslateService) {}

  addRequest(actrequest: ise_Request) {
    // tslint:disable-next-line:no-debugger
    debugger;
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept-Language': this.translate.getBrowserLang()
    });
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(
        PasswordResetService.wsUrl + 'action=WSAddRequestAction',
        JSON.stringify(actrequest),
        options
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
  }
  updateRequest(actrequest: ise_Request) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept-Language': this.translate.getBrowserLang()
    });
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(
        PasswordResetService.wsUrl + 'action=WSUpdateRequestAction',
        JSON.stringify(actrequest),
        options
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
  }

  loadRequestByWsToken(requesttoken: string) {
    requesttoken = encodeURIComponent(requesttoken);
    return this.http
      .get(
        PasswordResetService.wsUrl +
          'action=WSReloadRequestAction&requestwstoken=' +
          requesttoken
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
  }
  loadRequestByADToken(requesttoken: string) {
    requesttoken = encodeURIComponent(requesttoken);
    return this.http
      .get(
        PasswordResetService.wsUrl +
          'action=WSReloadRequestByADTokenAction&requestadtoken=' +
          requesttoken
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
  }
  loadCustomerByCustomerIdentification(customerIdentification: string) {
    let url = '';
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept-Language': this.translate.getBrowserLang()
    });
    const customeridentification = encodeURIComponent(customerIdentification);
    url =
      PasswordResetService.wsUrl +
      'action=WSLoadCustomerByIdentificationAction&ci=' +
      customeridentification;
    return this.http
      .get(url, { headers: headers })
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
  }
  validateResetRequest(token: string) {
    const tokenforurl = encodeURIComponent(token);
    return this.http
      .get(
        PasswordResetService.wsUrl +
          'action=WSValidateAdTokenAction&token=' +
          tokenforurl
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
  }
  validateRecaptchaResult(recaptchaUserToken: string) {
    return this.http
      .get(
        PasswordResetService.wsUrl +
          'action=WSRecaptchaAction&recaptchaUserToken=' +
          recaptchaUserToken
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
  }
  ContactMethodChanged(value) {
    console.log(value);
  }

  private handleSessionState(res: Response): any {
    try {
      if (document.cookie) {
        const cookies = document.cookie.split(';');
        const isAdminVerified = cookies[0].split('=')[1];
        if (isAdminVerified) {
          return this.extractData(res);
        } else {
          return false;
        }
      }
    } catch (ex) {
      return res;
    }
  }
  private extractData(res: Response) {
    try {
      const body = res.json();
      return body || {};
    } catch (ex) {
      return res;
    }
  }
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error) {
      if (error.status === 404) {
        errMsg = `${error.status} - ${error.statusText || ''}`;
      } else {
        if (PasswordResetService.isJsonString(error.statusText)) {
          errMsg = JSON.parse(error.statusText);
        } else if (PasswordResetService.isJsonString(error.toString())) {
          errMsg = JSON.parse(error.toString());
        } else {
          errMsg = error.toString() ? error.toString() : error.statusText;
        }

        let body: any = '';
        try {
          body = error.json();
        } catch (jsonEx) {
          // Test
        }
        let err = '';
        if (body !== '') {
          if (PasswordResetService.isJsonString(body)) {
            err = JSON.parse(body);
          } else if (body.error) {
            err = body.error;
          } else if (error.text() !== '') {
            err = error.text();
          }
        }
        if (body[0]) {
          if (body[0].error) {
            if (error.status) {
              if (error.status === 510) {
                return throwError(error);
              }
            }
            return throwError(body[0].error);
          }
        }
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      }
    }
    if (error.status) {
      if (error.status === 510) {
        return throwError(error);
      }
    }

    return throwError(errMsg);
  }
}
