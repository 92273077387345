import { Component, OnInit } from '@angular/core';
import { PasswordResetterFormComponent } from './passwordresetter/form.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ise-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'ISE Passwort Resetter';
  ngOnInit() { }
  constructor() { }
}
