﻿import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { TranslateService } from '@ngx-translate/core';
import {
  faExclamationTriangle,
  faCheck
} from '@fortawesome/free-solid-svg-icons';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ise-status-dialog',
  templateUrl: './statusdialog.component.html'
})
export class StatusComponent implements AfterViewInit {
  @ViewChild('StatusDialogModal')
  modal: BsModalComponent;
  statusText = '';
  loading = false;
  statusok = true;
  retries: number;
  faExclamationTriangle = faExclamationTriangle;
  faCheck = faCheck;
  manualLink = '';

  @Output()
  onClose: EventEmitter<any> = new EventEmitter(false);
  @Output()
  onOpen: EventEmitter<any> = new EventEmitter(false);


  constructor(
    private element: ElementRef,
    private translate: TranslateService
  ) {
    translate.addLangs(['en', 'fr', 'de']);
    const browserLang = translate.getBrowserLang();
    if (browserLang !== '') {
      translate.setDefaultLang(browserLang);
    } else {
      translate.setDefaultLang('de');
    }
    translate.use(browserLang.match(/en|fr|de/) ? browserLang : 'en');
  }

  ngAfterViewInit() {
    this.modal.onClose.subscribe(() => {
      this.onClose.emit(undefined);
    });
    this.modal.onDismiss.subscribe(() => {
      this.onClose.emit(undefined);
    });
    this.modal.onOpen.subscribe(() => {
      this.onOpen.emit(undefined);
    });
  }
  dismiss() {
    this.modal.dismiss();
  }
  close() {
    this.modal.close();
  }
  open(text) {
    this.statusText = text;
    this.modal.open();
  }
}
