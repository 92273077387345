﻿export class Resources {
         public customerAdminIdentifier = "";
         public activeDirectoryFieldValue = "";
         public requestNewPasswordControl = "";
         public requestADToken = "";
         public captchaResponse = false;

         public disablePasswordInput = true;
         public disableMail = false;
         public loading = false;
         public submitted = false;
         public isAdmin = false;
         public finished = false;

         // public noTokenMessage = 'Kein Token in der Anfrage!';
         // public noPasswordMatchMessage = 'Fehler! Passworte stimmen nicht überein.';
         // public noPasswordLengthMessage = 'Fehler! Passwort muss mindestens {0} Zeichen haben.';
         // public noPasswordConditionMatchMessage = 'Fehler! Das neue Passwort stimmt nicht mit den Passwort-Richtlinien überein.
         // Achten Sie darauf, dass Sie mind. 1 Gross- & 1 Kleinbuchstabe, 1 Zahl und 1 Symbol haben';
         // public noCaptchaMessage = 'Captcha nicht ausgefüllt!';
         // public TokenValidationFailedMessage = 'Validierung fehlgeschlagen!';
         // public settingsDialogUpdatedMessage = 'Einstellungen wurden erfolgreich aktualisiert.';
         // public addRequestResultMessage = 'Ihre Anfrage wurde erfolgreich an den Server gesendet. {0}';
         // public emailMessage = 'Bitte überprüfen Sie Ihr E-Mail Postfach.';
         // public smsMessage = 'Bitte überprüfen Sie Ihre SMS.';
         // public ADTokenValidationMessage = 'Anfrage wurde erfolgreich geladen. Bitte geben Sie nun ein neues Passwort ein.';
         // public updateRequestResultMessage = 'Ihr neues Passwort wurde erfolgreich an den Server gesendet.';
         // public updateRequestResponseMessage = 'Ihr Passwort wurde erfolgreich zurückgesetzt.
         // Sie können sich nun mit dem neuen Passwort anmelden.';
       }
