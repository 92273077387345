import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Third Party
// import { RecaptchaModule } from 'ng-recaptcha';
import { BsModalModule } from 'ng2-bs3-modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { StatusComponent } from './shared/statusdialog.component';
import { PasswordResetterFormComponent } from './passwordresetter/form.component';
import { MyMissingTranslationHandler } from './core/translation';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, PasswordResetterFormComponent, StatusComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    }),
    // RecaptchaModule.forRoot(),
    BsModalModule,
    FontAwesomeModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
