﻿export class WebUtilities {
    public static getParameter(param: string): string {
        return WebUtilities.getParameterFromString(document.URL, param);
    }
    public static mouseoverPass(id: string) {
        let obj: HTMLInputElement = <HTMLInputElement>document.getElementById(id);
        obj.type = 'text';
    }
    public static mouseoutPass(id: string) {
        let obj: HTMLInputElement = <HTMLInputElement>document.getElementById(id);
        obj.type = 'password';
    }
    public static colorLuminance(hex, lum) {
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;
        let rgb = '#', c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ('00' + c).substr(c.length);
        }
        return rgb;
    }
    public static getCookie(name) {
        let value = '; ' + document.cookie;
        let parts = value.split('; ' + name + '=');
        // tslint:disable-next-line:curly
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    static validatePasswordsCondition(pass: string, pass2: string, bcomplexity: boolean): boolean {
        let bresult = false;
        if (bcomplexity) {
            let anUpperCase = /[A-Z]/;
            let aLowerCase = /[a-z]/;
            let aNumber = /[0-9]/;
            let aSpecial = /[!|@|#|$|%|^|&|*|(|)|+|-|:|;|,|.|[|]|{|}|_]/;
            let numUpper = 0;
            let numLower = 0;
            let numNums = 0;
            let numSpecials = 0;
            for (let i = 0; i < pass.length; i++) {
                if (anUpperCase.test(pass[i])) {
                    numUpper++;
                } else if (aLowerCase.test(pass[i])) {
                    numLower++;
                } else if (aNumber.test(pass[i])) {
                    numNums++;
                } else if (aSpecial.test(pass[i])) {
                    numSpecials++;
                }
            }
            if (numUpper > 0 && numLower > 0 && numNums > 0) {
                bresult = true;
            } else if (numUpper > 0 && numLower > 0 && numSpecials > 0) {
                bresult = true;
            } else if (numUpper > 0 && numNums > 0 && numSpecials > 0) {
                bresult = true;
            } else if (numLower > 0 && numNums > 0 && numSpecials > 0) {
                bresult = true;
            }
            if (bresult) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    static validatePasswordLength(l: number, p: string): boolean {
        if (p.length >= l) {
            return true;
        } else {
            return false;
        }
    }

    static validatePasswordMatch(pass: string, pass2: string): boolean {
        if (pass === pass2) {
            return true;
        } else {
            return false;
        }
    }

    private static getParameterFromString(strUrl: string, param: string): string {
        try {
            param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            let regex = new RegExp('[\\?&]' + param + '=([^&#]*)'),
                results = regex.exec(strUrl);
            return results == null ? '' : decodeURIComponent(results[1]);
        } catch (ex) {
            return '';
        }
    }
}
