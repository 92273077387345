﻿import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class AsyncBackgroundWorker {
  m_index = 0;
  m_timeout = 0;
  m_interval = 0;
  m_unsubscribecondition = false;
  m_observercallback: any;
  m_workerfunction: any;
  m_parametersforworkerfunction: any;
  m_observable: any;
  m_subject: any;

  public observercallback(observercallback: any) {
    this.m_observercallback = observercallback;
  }

  public workerfunction(workerfunction: any) {
    this.m_workerfunction = workerfunction;
  }

  public parametersforworkerfunction(parametersforworkerfunction: any) {
    this.m_parametersforworkerfunction = parametersforworkerfunction;
  }

  public timeout(timeout: number) {
    this.m_timeout = timeout;
  }

  public unsubcondition(bunsub: boolean) {
    this.m_unsubscribecondition = bunsub;
  }

  public interval(interval: number) {
    this.m_interval = interval;
  }

  constructor(
    timeout: number,
    interval: number,
    observercallback: any,
    workerfunction: any,
    parametersforworkerfunction: any
  ) {
    if (timeout) {
      this.m_timeout = timeout;
    }
    if (interval) {
      this.m_interval = interval;
    }
    if (observercallback) {
      this.m_observercallback = observercallback;
    }
    if (workerfunction) {
      this.m_workerfunction = workerfunction;
    }
    if (parametersforworkerfunction) {
      this.m_parametersforworkerfunction = parametersforworkerfunction;
    }
  }

  DoWork() {
    this.m_observable = timer(0, this.m_interval).pipe(
      takeUntil(timer(this.m_timeout))
    );
    this.m_subject = this.m_observable.subscribe({
      next: x => {
        this.m_index = this.m_index + 1;
        this.m_workerfunction(
          this.m_parametersforworkerfunction,
          this.m_index,
          this
        );
      },
      error: err => this.m_observercallback(null, err),
      complete: () => this.m_observercallback('completed', null)
    });
  }
}
