import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ApplicationRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ise_CustomerSettings } from './shared/customersettings';
import { ise_Request } from './shared/request';
import { PasswordResetService } from './shared/passwordresetter.service';
import { Resources } from './shared/resources';

import { AsyncBackgroundWorker } from './../shared/_asyncbackgroundworker';
import { WebUtilities } from './../shared/_webutilities';
import { StatusComponent } from './../shared/statusdialog.component';
import { faEye } from '@fortawesome/free-solid-svg-icons';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ise-app-form',
  templateUrl: './form.component.html',
  providers: [PasswordResetService]
})
export class PasswordResetterFormComponent implements OnInit {
  title = 'ISE Passwort Resetter';
  mode = 'Observable';
  errorMessage = '';
  faEye = faEye;
  settings: Resources;
  customerSettings: ise_CustomerSettings;
  requestRecord: ise_Request;

  @ViewChild('statusDialog')
  statusDialog: StatusComponent;

  constructor(
    private passwordresetservice: PasswordResetService,
    private translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    private _appRef: ApplicationRef
  ) {
    translate.addLangs(['en', 'fr', 'de']);
    const browserLang = translate.getBrowserLang();
    if (browserLang !== '') {
      translate.setDefaultLang(browserLang);
    } else {
      translate.setDefaultLang('de');
    }
    translate.use(browserLang.match(/en|fr|de/) ? browserLang : 'en');
  }

  ngOnInit() {
    grecaptcha.ready(() =>
      grecaptcha
        .execute('6Ld8r3kUAAAAAHwScBYzDN2UPZUmCbLfAYOp53ay', {
          action: 'passwordresetter'
        })
        .then(token => {
          this.validateCaptchaResponse(token);
        })
    );
    this.customerSettings = new ise_CustomerSettings();
    this.settings = new Resources();
    this.requestRecord = new ise_Request();

    this.settings.loading = false;

    this.getUrlParams();
  }
  validateCaptchaResponse(token: string) {
    this.passwordresetservice.validateRecaptchaResult(token).subscribe(
      res => {
        if (res.ok === true) {
          this.settings.captchaResponse = true;
        }
      },
      err => {
        this.settings.captchaResponse = false;
        console.log(err);
      }
    );
  }
  colourIsLight(r, g, b) {
    const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    console.log(a);
    return a < 0.5;
  }
  hexToRgb(hex) {
    const bigint = parseInt(hex, 16);
    // tslint:disable-next-line:no-bitwise
    const r = (bigint >> 16) & 255;
    // tslint:disable-next-line:no-bitwise
    const g = (bigint >> 8) & 255;
    // tslint:disable-next-line:no-bitwise
    const b = bigint & 255;

    return r + ',' + g + ',' + b;
  }
  LoadCompleted() {
    const ButtonElements = document.querySelectorAll('.ms-Button');
    for (let index = 0; index < ButtonElements.length; index++) {
      const msButtonElement = <HTMLButtonElement>ButtonElements[index];
      if (msButtonElement != null) {
        msButtonElement.style.backgroundColor = this.customerSettings.ColorCode;
        try {
          // tslint:disable-next-line:no-unused-expression
          new fabric['Button'](ButtonElements[index], function() {});
        } catch (ex) {
          console.log(ex);
        }
      }

      const spanelements = document.getElementsByClassName('ise-color-fn');
      for (let innerIndex = 0; innerIndex < spanelements.length; innerIndex++) {
        const msSpanElement = <HTMLSpanElement>spanelements[innerIndex];
        const rgb = this.hexToRgb(this.customerSettings.ColorCode);
        const textColour = this.colourIsLight(rgb[0], rgb[2], rgb[4])
          ? 'black'
          : 'white';
        msSpanElement.style.color = textColour;
      }
    }
    const TextFieldElements = document.querySelectorAll('.ms-TextField');
    for (
      let fieldIndex = 0;
      fieldIndex < TextFieldElements.length;
      fieldIndex++
    ) {
      try {
        // tslint:disable-next-line:no-unused-expression
        new fabric['TextField'](TextFieldElements[fieldIndex]);
      } catch (ex) {
        console.log(ex);
      }
    }
    const element = document.querySelectorAll('#customer_logo');
    for (let i = 0; i < element.length; i++) {
      const customerlogoelement = <HTMLImageElement>element[i];
      customerlogoelement.setAttribute(
        'src',
        'data:image/png;base64,' + this.customerSettings.LogoBase64
      );
    }
    const illustrationDiv = document.querySelectorAll('.illustrationContainer');
    for (let i = 0; i < illustrationDiv.length; i++) {
      const illustrationContainer = <HTMLDivElement>illustrationDiv[i];
      if (illustrationContainer !== undefined) {
        illustrationContainer.style.backgroundImage =
          'url(data:image/jpg;base64,' +
          this.customerSettings.ADFSImageBase64 +
          ')';
      }
    }
    const customerPageTextElement = document.querySelectorAll(
      '#customerPageText'
    );
    for (let i = 0; i < customerPageTextElement.length; i++) {
      const customerPageText = <HTMLSpanElement>customerPageTextElement[i];
      customerPageText.innerHTML = this.customerSettings.CustomerPageText;
    }
    const customerSuccessMessageTextElement = document.getElementById(
      'customerSuccessMessage'
    );
    if (customerSuccessMessageTextElement != null) {
      customerSuccessMessageTextElement.innerHTML = this.customerSettings.SuccessMessage;
    }
  }
  getUrlParams() {
    let customerIdentifier = WebUtilities.getParameter('ci');
    // tslint:disable-next-line:no-debugger
    debugger;
    if (location.hostname.match('password.edubern.ch')) {
      customerIdentifier = '1009454';
    }
    const requestADToken = WebUtilities.getParameter('adst');
    const activeDirectoryFieldValue = WebUtilities.getParameter('adfv');
    if (requestADToken) {
      this.settings.requestADToken = requestADToken;
    }
    if (activeDirectoryFieldValue) {
      this.settings.activeDirectoryFieldValue = activeDirectoryFieldValue;
    }
    this.loadCustomerByCustomerIdentidier(customerIdentifier);
  }

  loadCustomerByCustomerIdentidier(customerIdentificationString) {
    if (customerIdentificationString) {
      this.passwordresetservice
        .loadCustomerByCustomerIdentification(customerIdentificationString)
        .subscribe(
          response => this.loadCustomerFinished(response),
          error => this.alertError(error)
        );
    } else {
      let error = '';
      this.translate.get('NoTokenMessage').subscribe(res => {
        error = res;
        this.alertError(error);
      });
    }
  }

  loadCustomerFinished(response) {
    if (response) {
      this.customerSettings.loadfromdata(response, this.translate);
      if (this.settings.requestADToken) {
        this.validateAdToken();
      } else {
        this.settings.loading = false;
        this.LoadCompleted();
        return;
      }
    } else {
      let error = '';
      this.translate.get('TokenValidationFailedMessage').subscribe(res => {
        error = res;
        this.alertError(error);
      });
    }
    this.LoadCompleted();
  }

  validateAdToken() {
    this.passwordresetservice
      .validateResetRequest(this.settings.requestADToken)
      .subscribe(
        response => this.validateAdTokenFinished(response),
        error => this.alertError(error)
      );
  }
  validateAdTokenFinished(response: any): boolean {
    if (response != null) {
      this.settings.loading = false;
      this.settings.disablePasswordInput = false;
      this.settings.disableMail = true;
      this.requestRecord = response;
      this.requestRecord.requestId = response.RequestId;
      this.requestRecord.requestStatus = response.RequestStatus;
      this.requestRecord.requestCustomerName = response.RequestCustomerName;
      this.requestRecord.requestEmail = response.RequestEmail;
      this.requestRecord.requestNewPassword = response.RequestNewPassword;
      this.requestRecord.requestADToken = response.RequestADToken;
      this.requestRecord.requestCustomerIdentifier =
        response.RequestCustomerIdentifier;
      this.requestRecord.requestWSToken = response.RequestWSToken;
      this.requestRecord.requestContactMethod = response.RequestContactMethod;
      if (this.settings.activeDirectoryFieldValue) {
        this.requestRecord.requestADFieldValue = this.settings.activeDirectoryFieldValue;
      }
    } else {
      let msg = '';
      this.translate.get('TokenValidationFailedMessage').subscribe(res => {
        msg = res;
        this.alertError(msg);
      });
    }
    return true;
  }

  onSubmit() {
    // this.settings.captchaResponse = grecaptcha.getResponse();
    if (!this.settings.disablePasswordInput) {
      this.settings.captchaResponse = true;
    }
    if (this.settings.captchaResponse) {
      this.settings.submitted = true;
      if (this.settings.disablePasswordInput) {
        this.addRequest();
      } else {
        if (
          WebUtilities.validatePasswordsCondition(
            this.requestRecord.requestNewPassword,
            this.settings.requestNewPasswordControl,
            this.customerSettings.EnablePasswordComplexity
          )
        ) {
          if (
            WebUtilities.validatePasswordMatch(
              this.requestRecord.requestNewPassword,
              this.settings.requestNewPasswordControl
            )
          ) {
            if (
              WebUtilities.validatePasswordLength(
                this.customerSettings.PasswordMinLength,
                this.requestRecord.requestNewPassword
              )
            ) {
              this.updateRequest();
            } else {
              let msg = '';
              this.translate.get('NoPasswordLengthMessage').subscribe(res => {
                msg = res;
              });
              this.alertError(
                msg.replace(
                  '{0}',
                  this.customerSettings.PasswordMinLength.toString()
                )
              );
            }
          } else {
            let msg = '';
            this.translate.get('NoPasswordMatchMessage').subscribe(res => {
              msg = res;
              this.alertError(msg);
            });
          }
        } else {
          let msg = '';
          this.translate
            .get('NoPasswordConditionMatchMessage')
            .subscribe(res => {
              msg = res;
              this.alertError(msg);
            });
        }
      }
      return true;
    } else {
      let msg = '';
      this.translate.get('NoCaptchaMessage').subscribe(res => {
        msg = res;
        this.alertError(msg);
      });
      this.settings.submitted = false;
      return false;
    }
  }

  addRequest() {
    this.requestRecord.requestId = '0';
    this.requestRecord.requestStatus = 0;
    this.requestRecord.requestADToken = this.requestRecord.requestADToken;
    this.requestRecord.requestCustomerName = this.customerSettings.CustomerName;
    this.requestRecord.requestEmail = this.requestRecord.requestEmail;
    this.requestRecord.requestADFieldValue = this.requestRecord.requestADFieldValue;
    this.requestRecord.requestNewPassword = '';
    this.requestRecord.requestCustomerIdentifier = this.customerSettings.CustomerIdentifier;
    this.requestRecord.requestWSToken = this.requestRecord.requestWSToken;
    if (this.customerSettings.IContactMethod > 1) {
      this.requestRecord.requestContactMethod = this.requestRecord.requestContactMethod;
    } else {
      this.requestRecord.requestContactMethod = this.customerSettings.ContactMethods[0].contactmethodid;
    }

    this.passwordresetservice
      .addRequest(this.requestRecord)
      .subscribe(
        request => [this.addRequestFinished(request)],
        error => this.alertError(error)
      );
  }
  addRequestFinished(result: any) {
    if (result != null) {
      this.asyncBackgroundTask();
      this.requestRecord.requestWSToken = result._body;
      this.statusDialog.loading = true;
      this.translate.get('ProcessingMessage').subscribe(res => {
        const msg = res;
        this.statusDialog.open(msg);
      });
    }
  }
  updateRequest() {
    this.requestRecord.requestStatus = 2;
    this.passwordresetservice
      .updateRequest(this.requestRecord)
      .subscribe(
        request => [this.updateRequestFinished(request)],
        error => this.alertError(error)
      );
  }
  updateRequestFinished(result: any) {
    if (result != null) {
      this.requestRecord.requestADToken = result;
      this.statusDialog.loading = true;

      this.translate.get('ProcessingMessage').subscribe(res => {
        const msg = res;
        this.statusDialog.open(msg);
      });
      this.asyncBackgroundTask();
    }
  }

  asyncBackgroundTask() {
    const timeout = 100000;
    const interval = 5000;
    this.statusDialog.retries = timeout / interval;
    const asyncWorkerInstance = new AsyncBackgroundWorker(
      timeout,
      interval,
      this.observerCallback,
      this.loadRequestByRequesttokenAsync,
      this
    );
    asyncWorkerInstance.DoWork();
  }
  loadRequestByRequesttokenAsync(
    instance: PasswordResetterFormComponent,
    index: number,
    asyncWorker: AsyncBackgroundWorker
  ) {
    if (asyncWorker) {
      const maxNumberOfRetries = asyncWorker.m_timeout / asyncWorker.m_interval;
      if (index >= maxNumberOfRetries) {
        asyncWorker.m_subject.complete();
        instance.closeDialog();
        instance.statusDialog.loading = false;
        instance.statusDialog.statusok = true;
        instance.statusDialog.close();
        instance.alertError('Timeout.');
      }
    }
    if (instance.settings.disablePasswordInput) {
      instance.passwordresetservice
        .loadRequestByWsToken(instance.requestRecord.requestWSToken)
        .subscribe(
          result => [
            instance.loadRequestByrequestTokenAsyncFinished(result),
            asyncWorker.m_subject.complete()
          ],
          error =>
            instance.loadRequestByrequestTopenAsyncError(error, asyncWorker)
        );
    } else {
      instance.passwordresetservice
        .loadRequestByADToken(instance.requestRecord.requestADToken)
        .subscribe(
          result => [
            instance.loadRequestByrequestTokenAsyncFinished(result),
            asyncWorker.m_subject.complete()
          ],
          error =>
            instance.loadRequestByrequestTopenAsyncError(error, asyncWorker)
        );
    }
  }

  radioButtonChanged(value) {
    console.log(value);
  }

  loadRequestByrequestTokenAsyncFinished(result) {
    this.statusDialog.retries = this.statusDialog.retries - 1;
    const status = result.RequestStatus;
    console.log(status);
    if (this.settings) {
      if (!this.settings.disablePasswordInput) {
        if (status === 3) {
          this.closeDialog();
          this.statusDialog.loading = false;
          this.statusDialog.statusok = true;
          this.statusDialog.close();
          this.settings.finished = true;
          this._changeDetector.detectChanges();
          // tslint:disable-next-line:no-debugger
          debugger;
          this._appRef.tick();
          this.LoadCompleted();
          return;
        }
      }
    }
    if (status === 1) {
      this.closeDialog();
      this.statusDialog.loading = false;
      this.statusDialog.statusok = true;

      let message = '';
      if (this.requestRecord.requestContactMethod === 2) {
        this.translate.get('SMSMessage').subscribe(res => {
          this.translate
            .get('AddRequestResultMessage', { SMSOrEmailMessage: res })
            .subscribe(msg => {
              message = msg;
              this.statusDialog.open(message);
              return;
            });
        });
      } else {
        this.translate.get('EmailMessage').subscribe(res => {
          this.translate
            .get('AddRequestResultMessage', { SMSOrEmailMessage: res })
            .subscribe(msg => {
              message = msg;
              this.statusDialog.open(message);
              return;
            });
        });
      }

      //   if (this.requestRecord.requestContactMethod === 2) {
      //     message = message.replace('{SMSOrEmailMessage}', SMSMessage);
      //   } else {
      //     message = message.replace(
      //       '{SMSOrEmailMessage}',
      //       this.translate.get('EmailMessage')
      //     );
      //   }
    } else if (status === 99) {
      this.closeDialog();
      this.statusDialog.loading = false;
      this.statusDialog.statusok = false;
      this.statusDialog.open(result.RequestErrorMessage);
      return;
    }
  }
  loadRequestByrequestTopenAsyncError(
    error: any,
    asyncWorker: AsyncBackgroundWorker
  ) {
    this.statusDialog.retries = this.statusDialog.retries - 1;
    if (error.status) {
      if (error.status === 510) {
        this.statusDialog.close();
        asyncWorker.m_subject.complete();
        this.alertError(error.json());
      }
    }
  }
  alertError(error: any) {
    this.statusDialog.open('');
    this.statusDialog.loading = false;
    this.statusDialog.statusok = false;
    this.statusDialog.statusText = error;
  }

  // tslint:disable-next-line:no-empty
  observerCallback(result: any) {}
  // tslint:disable-next-line:no-empty
  // resolved(captchaResponse: string) {}

  openDialog(text: string) {
    this.statusDialog.open(text);
  }
  closeDialog() {
    this.statusDialog.close();
  }
}

declare var grecaptcha;
declare var window;
declare var fabric;
window.WebUtilities = WebUtilities;
