﻿// tslint:disable-next-line:class-name
export class ise_Request {
    public requestId: string;
    public requestStatus: number;
    public requestADToken: string;
    public requestCustomerName: string;
    public requestEmail: string;
    public requestADFieldValue: string;
    public requestNewPassword: string;
    public requestCustomerIdentifier: string;
    public requestWSToken: string;
    public requestErrorMessage: string;
    public requestContactMethod: number;
    public requestLanguage: string;
}
